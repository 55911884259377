<template>
  <!-- табличка дзвінків -->
  <b-row>
    <b-col>
      <!-- Прелоад для таблиці -->
      <preloaderVue v-if="preloaderTable" /> 
      <b-card>
        <b-row id="step1">
          <div class="col" style="display:flex" id="limits">
            <!-- Пошук по таблиці -->
            <searchBox @searchB="searchB" v-if="this.objParams && this.objParams.hasOwnProperty('search')" :typesearch="this.typesearch"/>
          </div>
          <div class="col-auto">
            <div class="hstack gap-2">
              <div>
                <!-- вызов модалки на добавление -->
                <b-button
                  @click="create"
                  class="btn btn-success"
                  ><i class="ri-add-line align-bottom me-1"></i></b-button
                >
              </div>

              <!-- инфо-блоки -->
              <!-- <div class="flex-shrink-0" v-if="this.ifinfoblocks == false">
                <button @click="this.$emit('infoblocks')" type="button" :class="`btn btn-${this.ifinfoblocks == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${this.ifinfoblocks == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
              </div> -->
                    <!-- фильтр к таблице -->
              <Filter :componentKey="this.componentKey" v-if="objParams && (dataParams && dataParams.status == true)" :dataParams="dataParams" :objParams="objParams" @changeFilter="changeFilter" @clearFilter="changeFilter('all')"  />
              <!-- кнопка удаления, если все отмечены -->
              <div class="flex-shrink-0" v-if="this.allSelected == true || this.checkrows != ''">
                <!-- кнопка видалення декількох заявок
                  З'являється коли є хоча б одна заявка на видалення
                -->
                <button class="btn btn-soft-danger" v-if="this.removeitem == true" @click="this.removeAll(this.checkrows)"><i class="ri-delete-bin-2-line"></i> {{ this.checkrows.length != 0 ? this.checkrows.length : ''}}</button>
              </div>
            </div>
          </div>
        </b-row>

        <b-row style="margin-top:10px;margin-left:5px;">
          <h5><b>{{ this.$t('Total') + ' ' + this.rows.length}}</b></h5>
        </b-row>

        <div style="display: flex;">
          <ul :class="`nav nav-tabs nav-tabs-custom`" role="tablist" style="width: 100%;overflow: auto!important;margin-bottom: 11px;">
            <li class="nav-item" >
              <a class="nav-link active All py-2 fs-14" data-bs-toggle="tab" id="All" href="#home1" role="tab"
              @click="changeFilter('all')" >
               <!--  Таб "Всі" -->
                <b>{{$t('all')}}</b>
                <!-- <span class="badge bg-primary align-middle ms-1">{{this.rows.length}}</span> -->
              </a>
            </li>
            <template v-for="(tab, index) in tabs" :key="index">
              <li class="nav-item" style="margin-left: 4px;" v-if="tab.checked == true"  >
                <a :class="`nav-link py-2 ${tab.tab} text-white fs-14`" data-bs-toggle="tab" :id="tab.tab" :href="`#${tab.tab}`" role="tab"
                  @click="changeTab(tab.obj, tab.value)" aria-selected="false" :style="`background-color: ${tab.color}`">
                  <b>{{ tab.title }}</b>
                  <!-- <span :class="`badge bg-black align-middle ms-1 cross-btn`" @click="removeCategory(tab, index)"><i class="ri-close-fill"></i></span> -->
                </a>
              </li>
            </template>
            <li class="nav-item copy" v-if="addtabs">
               <!-- Додати таб -->
                <a :class="`nav-link py-2 fs-14`"
                @click="addCategory()">
                  <b>{{ tabs.length > 0 ? $t('Edit') : $t('Add')}}</b>
                </a>
            </li>
          </ul>
          <div class="dropdown">
            <!-- Вибір колонки, яка буде відображатися -->
            <div class="btn-group">
              <button class="btn btn-ghost-secondary btn-icon btn-sm fs-18" type="button" id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                <i class="bx bx-table align-bottom"></i>
              </button>
              <ul class="dropdown-menu  list-group-flush border-dashed px-3" aria-labelledby="dropdownMenuClickableInside">
                <li class="list-group-item ps-0" v-for="item in columns" :key="item">
                  <div class="d-flex align-items-start">
                        <div class="form-check ps-0 flex-sharink-0">
                            <input type="checkbox" class="form-check-input ms-0" :id="item.name" v-model="item.status" :checked="item.status" @change="changeColumn(item)" />
                        </div>
                        <div class="flex-grow-1">
                            <label class="form-check-label mb-0 ps-2" :for="item.name">{{item.name}}</label>
                        </div>
                    </div>
                </li>
              </ul>
            </div>
            <!-- <div :class="`dropdown-menu dropdown-menu-end ${showDropdown == true ? 'show' : ''}`" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate(0px, 36px);">
              <ul class="list-group list-group-flush border-dashed px-3">
                  <li class="list-group-item ps-0" v-for="item in columns" :key="item">
                      <div class="d-flex align-items-start">
                          <div class="form-check ps-0 flex-sharink-0">
                              <input type="checkbox" class="form-check-input ms-0" :id="item.name" v-model="item.status" :checked="item.status" @change="changeColumn(item)" />
                          </div>
                          <div class="flex-grow-1">
                              <label class="form-check-label mb-0 ps-2" :for="item.name">{{item.name}}</label>
                          </div>
                      </div>
                  </li>
              </ul>
            </div> -->
          </div>
        </div>
        <div class="table-responsive table-card mb-4" v-if="rows.length > 0" @scroll="this.checkPosition()" style="max-height: 65vh !important;" ref='tableScrollHeight'>
          <!-- Таблиця дзвінків -->
          <table
            class="table align-middle table-nowrap mb-0"
            id="customerTable"
            ref='tableHeight'
          >
            <thead class="table-light text-muted">
            <tr>
              <th scope="col" style="width: 40px" v-if="this.checked == true || this.removeitem == true">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="checkAll"
                    value="option"
                    v-model="this.allSelected"
                    @click="selectAll"
                  />
                </div>
              </th>
              <template v-for="(column, ind) in columns" :key="column.id">
                <th
                  v-if="column.status == true"
                  class="text-left copy"
                  :style="{ position: 'relative', width: column.width + 'px' }"
                  @mousedown="startMove($event, ind)"
                  >
                  <div
                    style="cursor: col-resize;right: -5px;position: absolute;top: 0;width: 10px;bottom: 0;"
                    @mousedown="startMove($event, ind)"
                  ></div>
                      <v-btn
                        text
                        block
                        x-large
                        depressed
                      >
                      {{ column.name }}
                      </v-btn>
                  </th>
              </template>
            
              <th
                v-if="removeitem || resetpass"
                style="display: none;"
                scope="col">
                {{$t('action')}}
              </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(row, index) in rowsFilteredItems" :key="index">
            <tr class="tr_etner"  v-if="(row?.userPhone !== '0637006095') && (row?.userPhone !== '0737006095') && (row?.userPhone !== '0630476445')  && (row?.userPhone !== '0730476445')" >
            <th scope="row" v-if="this.checked == true || this.removeitem == true">
              <div class="form-check">
                <!-- вибрати дзвінок -->
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="checkAll"
                  v-model="checkrows"
                  @change="select(row, index)"
                  :value="row"
                />
              </div>
            </th>
            <template v-for="column in columns" :key="column">
              <td
                :class="column.align"
                v-if="column.status == true"
                @click="open(row)"
                >
                <!-- Звичайний рядок (відкриття по натисканню) -->
                  <template v-if="column.text != 'progressPercent'">
                    <!-- <span class="text-danger" v-if="row['old'+column.text] != null">{{row['old'+column.text]}} <b></b></span> чтобы посмотреть что клиент изменил пока его карточка была открыта-->
                    <div class="ctext-wrap c_block" v-if="column.copy == true" style="display:flex">
                        <div class="ctext-wrap-content">
                          <span v-html="column.mutate ? column.mutate(row[column.text], row) : row[column.text]" :class="`${column.mutateClass ? column.mutateClass(row[column.text], row) : 'none'}`"></span>
                        </div>
                        <i class="ri-file-copy-2-fill fs-14 copybtn"></i>
                    </div>
                    <span v-html="column.mutate ? column.mutate(row[column.text], row) : row[column.text]" v-else :class="`fs-12 ${column.mutateClass ? column.mutateClass(row[column.text], row) : 'none'}`"></span>
                  </template>

                  <!-- для прогресс-бара -->
                  <template v-if="column.text == 'progressPercent'">
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-1 text-muted fs-13">{{ row[column.text] }} %</div>
                        <div
                            class="progress progress-sm flex-grow-1 bg-soft-primary"
                            style="width: 68%"
                        >
                            <div
                            class="progress-bar bg-primary rounded"
                            role="progressbar"
                            :style="'width: ' + row[column.text] + '%'"
                            aria-valuenow="53"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            ></div>
                        </div>
                      </div>
                  </template>
                  <!-- для прогресс-бара -->

              </td>
            </template>
            <!-- колонка "Дія" -->
            <td v-if="removeitem || resetpass" style="display: none;">
              <ul class="list-inline hstack gap-2 mb-0">
                <!-- <li
                  class="list-inline-item"
                  :title="$t('Duplicate')"
                >
                  <button class="btn btn-soft-primary small_btn" v-if="this.removeitem == true" @click="duplicateBox(row)"><i class="ri-file-copy-2-fill"></i></button>
                </li> -->
                <li
                  v-if="resetpass"
                  class="list-inline-item"
                  :title="$t('reset')"
                >
                  <button class="btn btn-soft-primary small_btn " v-if="this.resetpass == true" @click="resetpassbox(row)"><i class="ri-lock-unlock-fill"></i></button>
                </li>
                <li
                  v-if="removeitem"
                  class="list-inline-item"
                  :title="$t('Delete')"
                >
                  <button class="btn btn-soft-danger small_btn " v-if="this.removeitem == true" @click="removeBox(row)"><i class="ri-delete-bin-2-line"></i></button>
                </li>
              </ul>
            </td>
            <!-- колонка "Дія" -->
            </tr>
              <template v-for="ch in row.child"  v-if="row.hasOwnProperty('child')">
                <tr class="tr_etner bg-info text-white" style="margin-left: 10px;margin-top: 10px;" @click="$emit('openTerminal', ch)">
                  <!-- <td
                    >
                    <span v-html="ch.terminalId" :class="`fs-12`"></span>
                  </td> -->
                  <td>
                    <span v-html="ch.terminalName" :class="`fs-12`"></span>
                  </td>
                </tr>
              </template>
              <!-- <template v-for="ch in row.child" :key="column">
                <button type="button" class="btn btn-outline-info waves-effect waves-light m-2">{{ch.terminalName}}</button>
              </template> -->
            <!-- </div> -->
          </template>
        </tbody>
      </table>
    </div>
        <!-- Якщо заявок нема, то повертаємо not found -->
        <div v-else>
          <div class="text-center" v-if="checks['024'] == false">
            <h5 class="mt-2">{{ $t('SorryNoResultFound') }}</h5>
            <p class="text-muted mb-0">{{ $t('WeveSearchedMoreThan2') }}</p>
          </div>
          <div class="text-center" v-else>
            <img class="gallery-img img-fluid mx-auto" style="max-width: 30%;" src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Naruto_Shiki_Fujin.svg/1200px-Naruto_Shiki_Fujin.svg.png" alt="">
            <h5 class="mt-2">{{ $t('pashNotFound') }}</h5>
            <p class="text-muted mb-0">{{ $t('pashNotFoundDesc') }}</p>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Filter from "@/components/globaltable/globalFilter.vue";
import searchBox from '@/components/globaltable/searchbox.vue';
import preloaderVue from '../../../components/globaltable/preloader.vue';
import { storeS } from "../../../store";
import {copy} from '@/usabilityScripts/copy.js'
import { Applications} from '@/API.js';

let apiServe = new Applications();  

export default {
  props:['data', 'title','checks','checked', 'checkedItems', 'datafilter', 'objParams', 'dataParams', 'tabs', 'rows', 'columns', 'oldrow', 'createdform', 'removeitem', 'resetpass', 'ifinfoblocks', 'addtabs', 'showpag'],
  data() {
    return{
      typesearch: '',
      checked: true,
      preloaderTable: true,
      rowsFilteredItems: [],
      interval: '',
      page: '',
      pages: '',
      allSelected: false,
      callNow: [],
      elses: [],
      checkrows: [],
      missing: [],
      fromSite: [],
    }
  },
  components: {
    preloaderVue,
    searchBox,
    Filter,
  },
  created(){
    this.typesearch = this.search = this.objParams.search
  },
  mounted() {
    this.getData()
    this.checkrows = this.checks

    if(sessionStorage.getItem('pageInCall')) {
      this.page = sessionStorage.getItem('pageInCall')
      sessionStorage.removeItem('pageInCall')
      if(document.querySelector('.table-card')) {
        setTimeout( () => {
          document.querySelector('.table-card').scrollTop = sessionStorage.getItem('scrollHeight') ? sessionStorage.getItem('scrollHeight') : '0'}
          , 777
        )
      }
    }

  },
  beforeUnmount() {
    if(document.querySelector('.table-responsive')){
      let posTop = document.querySelector('.table-responsive').scrollTop
      sessionStorage.setItem('pageInCall', this.page) // зберігаємо перед оновлення таблички її нумерацію
      sessionStorage.setItem('scrollHeight', posTop)
    }

  },
  methods: {
    copy(item){
        // копіювання
        if(copy(item) == true) {
            this.$toast.success(this.$t('Copied'));
        } else {
            this.$toast.error(`Fucking mistake, contact Borys`);
        }
        return
    },
    removeAll(list){
      // видалити все
      for(var item in list){
        apiServe.deleteRequest(list[item].requestId).then(result => {
          if(result.status === 'done') {
            if(item == (list.length - 1)) {
              this.checkrows = []
              this.$toast.success(this.$t('report_sent'))
            }
          }
        })
      }
    },
    create() {
      // створити заявку
      this.$emit('create')
    },
    selectAll: function() {
      // виділити всі
      this.checkrows = [];
      if (this.allSelected == false) {
          for (var item in this.rows) {
            this.checkrows.push(this.rows[item]);
          }
      }
      this.allSelected = true
      this.$emit('check', this.checkrows, this.allSelected) // виділяє всі дзвінки. Може спричинити зависання, бо операція складна навіть для маку
    },
    select(checked, item) {
      // вибрати заявку
        // this.allSelected = false;
        this.$emit('check', this.checkrows)
    },
    searchB(e){
      // пошук по таблиці
      clearTimeout(this.searchTime)
      this.search = e
      this.searchTime = setTimeout(() => this.$emit('search', e), 500)
      // this.$emit('search', e)
    },
    getData(addPage){
      // перевіряємо чи оновлення даних, чи додавання сторінки
      if(addPage) {
        this.page++ // додаємо сторінки
      } else {
        this.page = 1
        this.pages = this.rows.length/100 // знаходимо кількість сторінок і вибираємо першу
      }
      // формуємо категорії дзвінків
      this.fromSite = this.rows.filter(el => el.fromSite === 1) //дзвінки з сайту
      this.missing = this.rows.filter(el => el.action == 'missing').slice(0, this.page*100 - this.fromSite.length) // формуємо пропущенні
      this.callNow = this.rows.filter(el => el.status == 'callNow').slice(0, this.page*100 - this.missing.length - this.fromSite.length) // формуємо "Зателефонувати зараз"
      this.elses = this.rows.filter(el => 
        el.status !== 'callNow' && 
        el.action !== 'missing' && 
        el.formSite !== 1
      ).slice(0, this.page*100 - this.callNow.length - this.missing.length - this.fromSite.length)
      // формуємо категорії дзвінків
      
      // робимо об'єднані масиви
      let fromSite_missing = this.fromSite.concat(this.missing)
      let fromSiteMissing_callNow = fromSite_missing.concat(this.callNow)
      let fromSiteMissingCallNow_elses =  fromSiteMissing_callNow.concat(this.elses)
      // робимо об'єднані масиви

      this.rowsFilteredItems = new Set(fromSiteMissingCallNow_elses)
    },
    changeFilter(nameFilter, filterChange){
      // змінюємо фільтр
      this.$emit('changeFilter', nameFilter, filterChange)
    },
    checkPosition() {
      // перевіряємо положення таблиці 
      clearTimeout(this.interval)
      // this.$refs.tableScrollHeight.scrollTop - відстань від поточною точки, до найвищої
      // this.$refs.tableHeight.scrollTop - висота таблички з данними (не змінюється до оновлення)

      this.interval = setTimeout(() => {

        // Нам потребуется знать высоту документа и высоту экрана:
        const height = this.$refs.tableScrollHeight?.scrollTop
        const screenHeight = this.$refs.tableHeight.clientHeight

        // Они могут отличаться: если на странице много контента,
        // высота документа будет больше высоты экрана (отсюда и скролл).

        // Записываем, сколько пикселей пользователь уже проскроллил:

        // Обозначим порог, по приближении к которому
        // будем вызывать какое-то действие.
        // В нашем случае — четверть экрана до конца страницы:
        const threshold = screenHeight - screenHeight / 4

        if(height >= threshold) {
          this.getData(true)
        }
      }, 700)
    },
    changeTab(nameFilter, filterChange){
      // зміна табу
      this.eventBus.emit('refreshFilters', {})
      this.$emit('changeTab', nameFilter, filterChange)
    },
    addCategory(){
      // додавання табу
      this.$emit('addCategory')
    },
    open: function(e){
      // відкриття
      this.$emit("open", e);
    },
  }, 
  computed: {
    preloaderTable(){
      return storeS.preloaderTable
    }
  }
}
</script>

<style scoped>
.table-responsive.table-card.mb-4::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.table-responsive.table-card.mb-4::-webkit-scrollbar
{
	width: 5px;
    height: 5px;
	background-color: #F5F5F5;
}

.table-responsive.table-card.mb-4::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #302626af;
}
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.small_btn {
  max-width: 48px;
  max-height: 30px;
  padding: 0.3rem 1.0rem;
}
/* .tr_etner:hover .small_btn {
  color: #fff;
  background-color: #f06548;
  border-color: #f06548;
} */
[data-layout-mode="dark"] .tr_etner:hover {
  background: #09b39c45;
}

[data-layout-mode="dark"] td.left {
  font-weight: 600;
}

.cross-btn {
  display: none
}

a.nav-link:hover .cross-btn {
  display: inline-block
}

.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.table-nowrap td, .table-nowrap th {
  white-space: inherit;
  padding: 17px 16px;
  /* word-break: break-word; */
}
th.text-left {
  border-left: 1px solid #74747433;
  border-right: 1px solid #74747433;
}

.mainCalendarButton{
    height: 30px;
    border: none;
    border-radius: 0.25rem;
    background-color: #eff2f7;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    width: 200px;
}
[data-layout-mode="dark"] .mainCalendarButton {
  background-color: #1A1D1E !important;
  color: white
}
</style>
