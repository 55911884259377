<template>
    <!-- Модалка попередження, що заявку треба взяти в роботу -->
    <dialogModal>
        <template v-slot:title>{{ $t('needToWork') }}</template>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <p class="text-muted fs-15 mb-4"><b>{{ $t('yesToWork') }}.</b> </p>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- якщо є пермс 2000, то з'являється кнопка, що ми можемо просто переглянути заявку, не бравши її в роботу -->
                <button class="btn btn-danger" @click="this.$emit('open', this.id, 'view')" v-if="this.perms[2000] || this.perms[105]">{{ $t('review') }}</button>
                <button class="btn btn-success" @click="inJob">{{ $t('y') }}</button>
            </div>
        </template>
    </dialogModal>
</template>

<script>
import dialogModal from '@/components/modal/dialog'
import { storeS } from '../../../store';
export default {
    props: ['id', 'phone'],
    components: {
        dialogModal
    },
    created() {
        console.log(this);
    },
    methods: {
        inJob(){
            this.$emit('takeInWork', this.id, this.phone)
            this.$emit('close')
        }
    },
    computed:{
        perms(){
            return storeS.perms
        }
    }
}
</script>